// @flow

import React, { type Node } from 'react';
import { Link } from 'react-router';
import cx from 'classnames';

type Props = {
  children: Node | null,
  className: string,
  href: string,
  onClick: Function,
  rel: string | null,
  style: Object,
  target: string | null,
  theme: string | null,
  to: string | Object,
};

const defaultProps = {
  href: '',
  to: '',
  theme: null,
  className: '',
  children: null,
  style: null,
  target: null,
  rel: null,
  onClick: () => {},
};

const LinkButton = ({
  theme,
  className,
  href,
  to,
  children,
  style,
  target,
  rel,
  onClick,
  ...rest
}: Props) =>
  (href ? (
    /* eslint-disable react/jsx-indent-props, react/jsx-indent */
    <a
      {...rest}
      className={cx('button', theme, className)}
      style={style}
      href={href}
      target={target}
      onClick={onClick}
      rel={target === '_blank' ? 'noreferrer noopener' : rel}>
      {children}
    </a>
  ) : (
    <Link
      {...rest}
      className={cx('button', theme, className)}
      style={style}
      onClick={onClick}
      to={to}>
      {children}
    </Link>
  ));
/* eslint-enable react/jsx-indent-props, react/jsx-indent */

LinkButton.defaultProps = defaultProps;

export default LinkButton;
